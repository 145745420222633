<template>
  <div class="page" v-loading="loading">
    <Title :title="title || (yearNum + '年预算明细')">
      <div class="b-detail-t-s">
        <fm-date-picker style="width: 7rem;" @change="yearChange" v-model="year" type="year" placeholder="请选择年份"></fm-date-picker>
      </div>
    </Title>
    <div class="count">
      <div class="c-item" :class="{'c-item-nb': index === countData.length - 1}" v-for="(item, index) in countData" :key="index">
        <div class="t">{{item.title}}</div>
        <div class="num">
          <Money :money="item.value"></Money>
          <div class="img-d">
            <img :src="item.src" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="search">
        <div class="search-item">
          <div class="label">预算类型</div>
          <div class="form">
            <fm-select clearable v-model="search.type">
              <fm-option v-for="item in typeList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">初审科室</div>
          <div class="form">
            <fm-select clearable v-model="search.csOrgId">
              <fm-option v-for="item in csOrgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">申请部门</div>
          <div class="form">
            <fm-select clearable v-model="search.submitOrgId">
              <fm-option v-for="item in submitOrgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">状态</div>
          <div class="form">
            <fm-select clearable v-model="search.status">
              <fm-option v-for="item in statusList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <fm-btn @click="output">导出</fm-btn>
        </div>
      </div>
      <div class="table">
        <fm-table-new
          :simple-filter="false"
          :columns="columns"
          :auto-height="true"
          border="row"
          :export-file-name="title || (yearNum + '年预算明细')"
          ref="table"
          :data-list="showDetail"
          :stripe="true"
          emptyPlaceholder="-">
        </fm-table-new>
      </div>
    </div>
  </div>
</template>

<script>
import { budgetDetailRequest } from '../../api'

import {
  getDetailCount
} from './lib'

import Title from './components/cmp/title'
import Money from './components/cmp/money'

export default {
  components: {
    Title,
    Money
  },
  activated () {
    if (this.$route.query.id || this.$route.query.year) {
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.year = null
        this.title = this.$route.query.title
      }
      if (this.$route.query.year) {
        this.year = new Date(this.$route.query.year + '-02-01')
      }
    } else {
      this.year = new Date()
      this.id = null
      this.title = null
    }
    this.loadData()
  },
  data () {
    return {
      title: null,
      year: new Date(),
      id: null,
      csOrgList: [],
      submitOrgList: [],
      search: {
        type: null,
        submitOrgId: null,
        csOrgId: null,
        status: null
      },
      detail: [],
      loading: false,
      statusList: []
    }
  },
  computed: {
    typeList () {
      return this.$store.getters.budgetYearTypeList
    },
    yearNum () {
      return this.year ? this.year.getFullYear() : null
    },
    columns () {
      return [{
        field: 'typeText',
        title: '预算类型'
      },
      {
        field: 'goodsName',
        width: 200,
        title: '名称'
      },
      {
        field: 'goodsAssetsTypeName',
        width: 200,
        title: '国有资产'
      },
      {
        field: 'price',
        title: '预算单价'
      },
      {
        field: 'num',
        title: '预算数量'
      },
      {
        field: 'total',
        title: '预算总价'
      },
      {
        field: 'priceCe',
        title: '单价差额'
      },
      {
        field: 'numCe',
        title: '数量差额'
      },
      {
        field: 'totalCe',
        title: '总价差额'
      },
      {
        field: 'budgetApplyOrgName',
        title: '申请部门'
      },
      {
        field: 'applyOrgName',
        title: '初审部门'
      },
      {
        field: 'fgldUserName',
        title: '分管领导'
      },
      {
        field: 'statusText',
        title: '状态',
        fixed: 'right'
      }]
    },
    showDetail () {
      let allData = this.detail
      const status = this.search.status
      const type = this.search.type
      const submitOrgId = this.search.submitOrgId
      const csOrgId = this.search.csOrgId
      return allData.filter(item => {
        if (type && item.budgetYearType !== type) {
          return false
        }
        if (status && item.status !== status) {
          return false
        }
        if (submitOrgId && item.budgetApplyOrgId !== submitOrgId) {
          return false
        }
        if (csOrgId && item.applyOrgId !== csOrgId) {
          return false
        }
        return true
      })
    },
    count () {
      return getDetailCount(this.showDetail)
    },
    countData () {
      let total = {
        applyTotal: this.count.applyTotal || 0,
        normalApplyTotal: this.count.normal.applyTotal || 0,
        appendApplyTotal: this.count.append.applyTotal || 0
      }
      let data = [{
        title: '总预算',
        value: total.applyTotal,
        src: '/static/images/budget/l1.png'
      },
      {
        title: this.typeList[0].label,
        value: total.normalApplyTotal,
        src: '/static/images/budget/l22.png'
      },
      {
        title: this.typeList[1].label,
        value: total.appendApplyTotal,
        src: '/static/images/budget/l3.png'
      }]
      if (!total.normalApplyTotal) {
        data = [data[2]]
      }
      if (!total.appendApplyTotal) {
        data = [data[1]]
      }
      if (!total.appendApplyTotal && !total.normalApplyTotal) {
        return []
      }
      return data
    }
  },
  methods: {
    output () {
      this.$refs.table.openExport()
    },
    yearChange () {
      if (this.year) {
        this.search.status = null
        this.id = null
        this.title = null
        this.loadData()
      }
    },
    async loadData () {
      this.detail = []
      let parm = {}
      if (this.yearNum) {
        parm.year = this.yearNum
      }
      if (this.id) {
        parm.budgetYearId = this.id
      }
      this.loading = true
      this.detail = []
      if (this.$authFunsProxy.get) {
        this.detail = await budgetDetailRequest.getInfo(parm)
      } else if (this.$authFunsProxy.getByAuth) {
        this.detail = await budgetDetailRequest.getInfoByAuth(parm)
      }
      this.csOrgList = []
      let statusList = {}
      this.detail.forEach(v => {
        statusList[v.status] = v.statusText
        if (v.applyOrgId && !this.csOrgList.find(v1 => v1.key === v.applyOrgId)) {
          this.csOrgList.push({
            key: v.applyOrgId,
            label: v.applyOrgName
          })
        }
        if (v.budgetApplyOrgId && !this.submitOrgList.find(v1 => v1.key === v.budgetApplyOrgId)) {
          this.submitOrgList.push({
            key: v.budgetApplyOrgId,
            label: v.budgetApplyOrgName
          })
        }
        v.typeText = (this.typeList.find(v1 => v1.key === v.budgetYearType) || this.typeList[1]).label
        v.numCe = v.num - (v.usingNum || 0)
        v.totalCe = v.total - (v.usingTotal || 0)
        v.priceCe = v.usingTotal && v.usingNum ? Number((v.usingTotal / v.usingNum).toFixed(0)) - v.price : null
      })
      this.statusList = Object.keys(statusList).map(v => {
        return { label: statusList[v], key: v }
      })
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #EEF3FA;
  padding: 0 16px 12px;
  height: 100%;
  .b-detail-t-s {
    display: flex;
    height: 100%;
    margin-left: 20px;
    align-items: flex-end;
  }
  .count {
    height: 118px;
    display: flex;
    padding: 26px 0;
    background: #fff;
    border-radius: 6px;
    margin-top: 14px;
    justify-content: center;
  }
  .c-item {
    width: 33%;
    padding-left: 64px;
    border-right: 1px solid #D8D8D8;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .t {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }
  .c-item-nb {
    border-right: 0px solid #D8D8D8;
  }
  .num {
    position: relative;
    .img-d {
      width: 61px;
      height: 16px;
      position: absolute;
      right: 23%;
      top: 0;
      img {
        width: 100%;
        height: 100%;
      }

    }
  }
  .detail {
    margin-top: 14px;
    flex: 1;
    background: #FFFFFF;
    border-radius: 6px;
    .search {
      height: 30px;
      margin: 20px;
      display: flex;
      justify-content: space-between;
      .search-item {
        display: flex;
        align-items: center;
        .label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-right: 10px;
        }
      }
    }
    .table {
      height: calc(100% - 92px);
      padding: 0 20px 20px;
      width: 100%;
    }
  }
}
</style>

<style lang="less">
.page {
  .b-detail-t-s {
    input {
      background: rgba(0,0,0,0) !important;
    }
  }
}
</style>